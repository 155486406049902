<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar
          :inputs="inputs"
          :selects="selects"
          :timeRange="timeRange"
          @search="search"
          @reset="search"
        />
        <div class="control_box_bottom">
          <a-button
            type="primary"
            @click="showUpdateModal('add')"
            style="margin-right: 10px"
            >添加员工信息
          </a-button>
          <a-button
            type="primary"
            @click="showBatch"
            style="margin-right: 10px"
            >批量导入</a-button
          >
          <!-- <a-button
            type="primary"
            @click="deleteAll"
            style="margin-right: 10px"
            >全部删除</a-button
          > -->
          <a-button
            type="primary"
            @click="batchDelete"
            :disabled="!hasSelected"
            >删除选中项</a-button
          >
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table
          :columns="columns"
          :data-source="tableData"
          :loading="loading"
          :rowKey="(tableData) => tableData.id"
          :pagination="pagination"
          @change="handleTableChange"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
          <span slot="is_white" slot-scope="record">{{
            record.is_white == 1 ? "已加入" : "未加入"
          }}</span>
          <span slot="action" slot-scope="record">
            <a-button
              type="link"
              @click="showUpdateModal(record)"
            >编辑</a-button
          >
            <a-divider type="vertical" />
            <a-button type="link" @click="deleteFn(record)" >删除</a-button
          >
          </span>
        </a-table>
      </div>
    </div>

    <!-- 更新员工信息 -->
    <a-modal
      v-model="visible"
      :title="addWorker ? '添加员工' : '编辑员工'"
      @ok="handleSure"
      okText="保存"
    >
      <a-form-model
        :model="updateParam"
        ref="addForm"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item label="姓名">
          <a-input v-model="updateParam.name" placeholder="请输入姓名" />
        </a-form-model-item>
        <a-form-model-item label="手机">
          <a-input v-model="updateParam.mobile" placeholder="请输入手机号码" />
        </a-form-model-item>
        <a-form-model-item label="部门">
          <a-select v-model="updateParam.office" placeholder="请选择部门">
            <a-select-option
              v-for="item in offices"
              :key="item.key"
              :value="item.value"
              >{{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="职务">
          <a-select v-model="updateParam.post" placeholder="请选择职务">
            <a-select-option
              v-for="item in posts"
              :key="item.key"
              :value="item.value"
              >{{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 批量导入 -->
    <a-modal
      v-model="batchVisible"
      title="导入白名单"
      okText="确定"
      cancelText="取消"
      @ok="handleBatch"
    >
      <a-form-model
        ref="batchForm"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item label="文件(请选择.xls格式)">
          <a-upload
            accept=".xls"
            @change="changeFile"
            :before-upload="beforeUpload"
            :file-list="fileList"
          >
            <a-button type="primary">
              <a-icon type="upload" />导入Excel
            </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="下载">
          <a-button type="primary" @click="handleDonloadTemplate"
            >模板下载</a-button
          >
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  huserphoIndex,
  importWhiteList,
  WhiteListDownloadTemplate,
  huserphoCreate,
  huserphoUpdate,
  huserphoDelete,
  officesWithout,
  postsWithout,
  batchDeleteByIds,
  batchDeleteAll,
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data() {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "用餐人员管理",
        },
        {
          key: 1,
          title: "免审核名单",
        },
      ],

      //传给searchBar公共搜索组件的数据
      timeRange: {
        show: true,
        placeholderStart: "创建时间",
        placeholderEnd: "创建时间",
      },
      inputs: [
        {
          placeholder: "姓名",
          model: "name",
          width: 100,
        },
        {
          placeholder: "手机",
          model: "mobile",
          width: 120,
        },
      ],
      selects: [
        {
          model: "is_white",
          placeholder: "全部",
          width: 100,
          options: [
            {
              key: "",
              value: "全部",
            },
            {
              key: 0,
              value: "未加入",
            },
            {
              key: 1,
              value: "已加入",
            },
          ],
        },
      ],

      //请求参数
      queryParam: {
        page: 1,
        created_start: "",
        created_end: "",
        name: "",
        mobile: "",
        is_white: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "姓名",
          key: "name",
          dataIndex: "name",
        },
        {
          title: "手机",
          key: "mobile",
          dataIndex: "mobile",
        },
        {
          title: "部门",
          key: "office",
          dataIndex: "office",
        },
        {
          title: "职务",
          key: "post",
          dataIndex: "post",
        },
        {
          title: "状态",
          key: "is_white",
          scopedSlots: { customRender: "is_white" },
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },

      //批量
      batchVisible: false,
      fileList: [],

      updateId: "",
      updateParam: {
        mobile: "",
        name: "",
      },
      addWorker: false,
      visible: false,
      exportArr: [],
      offices: [],
      posts: [],
      selectedRowKeys: [],
    };
  },
  //监听属性 类似于data概念
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新和搜索一样的方法
    search(val) {
      this.queryParam = {
        page: 1,
        created_start: val.timeRange.startTime,
        created_end: val.timeRange.endTime,
        name: val.input.name ? val.input.name : "",
        mobile: val.input.mobile ? val.input.mobile : "",
        is_white: val.select.is_white === "" ? "" : val.select.is_white,
      };
      this.pagination.current = 1;
      this.getTableData();
    },

    //获取表格数据
    getTableData() {
      this.loading = true;
      huserphoIndex(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;

        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        this.selectedRowKeys = [];
        this.tableData = resultData.data;
        this.loading = false;
      });
    },

    //分页
    handleTableChange(pagination) {
      if (this.selectedRowKeys.length != 0) {
        this.$confirm({
          content: `是否删除当前页已勾选的${this.selectedRowKeys.length}条数据？`,
          onOk: () => {
            batchDeleteByIds({
              ids: this.selectedRowKeys,
              force: 0,
            })
              .then((res) => {
                if (res.data.data.num) {
                  this.$confirm({
                    content: `所选项中存在${res.data.data.num}条待加入名单，确认删除？`,
                    onOk: () => {
                      batchDeleteByIds({
                        ids: this.selectedRowKeys,
                        force: 1,
                      }).then((res) => {
                        this.$message.success("删除成功");
                        this.getTableData();
                      });
                    },
                  });
                } else {
                  this.$message.success("删除成功");
                  this.getTableData();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          },
          onCancel: () => {
            this.pagination.current = pagination.current;
            this.pagination.pageSize = pagination.pageSize;
            this.queryParam.page = pagination.current;
            this.getTableData();
          },
        });
      } else {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
        this.queryParam.page = pagination.current;
        this.getTableData();
      }
    },

    //打开批量导入模态框
    showBatch() {
      this.fileList = [];
      this.batchVisible = true;
    },

    //模板下载
    handleDonloadTemplate() {
      WhiteListDownloadTemplate().then((res) => {
        util.acceptFileStream(res.data, "白名单模板.xls");
      });
    },

    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },

    //上传文件
    changeFile(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      this.fileList = fileList;
    },

    //数据写入excel
    export2Excel() {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        const tHeader = ["姓名", "手机号码", "部门", "职务", "错误信息"]; // 导出的表头名信息
        const filterVal = ["name", "tel", "office", "post", "msg"]; // 导出的表头字段名，需要导出表格字段名
        const list = that.exportArr;
        const data = that.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "失败人员信息"); // 导出的表格名称，根据需要自己命名
      });
    },
    //格式转换
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    //批量导入
    handleBatch() {
      if (this.fileList.length == 0) {
        this.$message.info("请先上传文件");
        return;
      }
      const formData = new FormData();
      formData.append("white_file", this.fileList[0].originFileObj);
      importWhiteList(formData)
        .then((res) => {
          this.batchVisible = false;
          let data = res.data;
          this.$notification.open({
            message: `成功导入条数：${data.success_count}`,
            description: `${
              data.error_item.length == 0
                ? "失败条数：0"
                : "失败条数：" + data.error_item.length
            }`,
            duration: 0,
          });

          if (data.error_item.length != 0) {
            this.exportArr = data.error_item;
            this.export2Excel();
          }
          this.getTableData();
        })
        .catch((err) => {
          this.batchVisible = false;
          this.$notification.open({
            message: "导入失败",
            description: err.data.msg,
            duration: 0,
          });
        });
    },

    showUpdateModal(record) {
      if (record == "add") {
        this.addWorker = true;
        this.updateParam = {
          mobile: "",
          name: "",
          office: "",
          post: "",
        };
      } else {
        this.addWorker = false;
        this.updateId = record.id;
        this.updateParam = {
          mobile: record.mobile,
          name: record.name,
          office: record.office,
          post: record.post,
        };
      }
      this.visible = true;
    },

    handleSure: util.DebounceBy(function () {
      if (this.updateParam.name == "") {
        this.$message.info("请输入姓名");
        return;
      }
      if (this.updateParam.mobile == "") {
        this.$message.info("请输入手机号码");
        return;
      }
      if (!this.updateParam.office) {
        this.$message.info("请选择部门");
        return;
      }
      if (!this.updateParam.post) {
        this.$message.info("请选择职务");
        return;
      }
      if (this.addWorker) {
        huserphoCreate(this.updateParam)
          .then((res) => {
            this.$message.success("添加成功");
            this.queryParam.page = 1;
            this.pagination.current = 1;
            this.getTableData();
            this.visible = false;
          })
          .catch((err) => {
            if (err.status == 400) {
              this.$message.error(err.data.msg);
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          });
      } else {
        huserphoUpdate(this.updateId, this.updateParam)
          .then((res) => {
            this.$message.success("修改成功");
            // this.queryParam.page = 1;
            // this.pagination.current = 1;
            this.getTableData();
            this.visible = false;
          })
          .catch((err) => {
            if (err.status == 400) {
              this.$message.error(err.data.msg);
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          });
      }
    }, 3000),

    deleteFn(record) {
      let id = record.id;
      this.$confirm({
        content: `是否删除${record.name}的申请信息`,
        onOk: () => {
          huserphoDelete(id)
            .then((res) => {})
            .catch((err) => {
              if (err.status == 204) {
                this.$message.success("删除成功");
                // this.queryParam.page = 1;
                // this.pagination.current = 1;
                this.getTableData();
              } else {
                this.$message.error("删除失败");
              }
            });
        },
      });
    },

    //获取部门列表
    getOffices() {
      officesWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.offices.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //获取职务列表
    getPosts() {
      postsWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.posts.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //批量删除
    batchDelete() {
      this.$confirm({
        content: "确定删除选中项？",
        onOk: () => {
          batchDeleteByIds({
            ids: this.selectedRowKeys,
            force: 0,
          })
            .then((res) => {
              if (res.data.data.num) {
                this.$confirm({
                  content: `所选项中存在${res.data.data.num}条待加入名单，确认删除？`,
                  onOk: () => {
                    batchDeleteByIds({
                      ids: this.selectedRowKeys,
                      force: 1,
                    }).then((res) => {
                      this.$message.success("删除成功");
                      this.getTableData();
                    });
                  },
                });
              } else {
                this.$message.success("删除成功");
                this.getTableData();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    },

    //删除全部
    // deleteAll() {
    //   this.$confirm({
    //     content: `确定删除全部白名单？`,
    //     onOk: () => {
    //       batchDeleteAll({ force: 0 })
    //         .then((res) => {
    //           if (res.data.data.num) {
    //             this.$confirm({
    //               content: `所选项中存在${res.data.data.num}条待加入名单，确认删除？`,
    //               onOk: () => {
    //                 batchDeleteAll({ force: 1 }).then((res) => {
    //                   this.$message.success("删除成功");
    //                   this.getTableData();
    //                 });
    //               },
    //             });
    //           } else {
    //             this.$message.success("删除成功");
    //             this.getTableData();
    //           }
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     },
    //   });
    // },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTableData();
    this.getOffices();
    this.getPosts();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>